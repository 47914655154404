<template>
	<div>
		<h2>
			<i :class="iconName"></i> {{pageTitle}}
		</h2>

		<div class="row" v-if="dataLoaded">
			<div class="col-lg-4 col-md-12">
				<div class="build-form">
					<div class="sf-head">
						<h3>Design Form Appearance</h3>
					</div>
					<div class="bf-btns">
						<router-link tag="button" class="btn btn-outline-secondary"
									 :to="{name: 'dashboard.setup-form.choose-format'}">Back
						</router-link>
						<button @click="save()" class="btn btn-primary">Save & Continue</button>
					</div>
					<div class="bf-cnt">
						<div class="accordion" id="accordion">
							<Accordion title="Theme" ident="theme">
								<div class="sf-item">
									<Select id="theme-layout"
											label="Layout"
											:options="layoutOptions"
											v-model="form.alignment"
											:selectedOption="form.alignment">

									</Select>

									<ColorPicker v-if="isLanding"
												 label="Background Color" class="mt-3"
												 id="form-fields-background-color"
												 key="form-fields-background-color"
												 :color="form.backgroundColor"
												 v-model="form.backgroundColor"/>
									<RadioGroup
											v-if="!isLanding"
											id="form_add_margin"
											v-model="form.margin"
											:options="yesNoOptions"
											label="Add Margin"
											:inline="true"
									/>
								</div>
								<div class="sf-item">
									<Select id="theme-choose-font-family"
											label="Choose Font Family"
											:options="fontOptions"
											v-model="form.font"
											:selectedOption="form.font">
									</Select>
								</div>

							</Accordion>

							<Accordion title="Header" ident="header" v-if="isLanding">

								<div class="sf-item">
									<div class="form-group">
										<label>Upload Company Logo</label>
										<UploadImage :path="form.header.logo"
													 v-model="form.header.logo"
													 type="landing_logo"
													 key="landing_logo_path">
										</UploadImage>
									</div>
								</div>
								<div class="sf-item">

									<MyInput label="Company Name" class="mt-3"
											 placeholder="Company Name"
											 :value="form.header.companyName"
											 id="header-company-name"
											 key="header-company-name"
											 v-model="form.header.companyName"
									></MyInput>

									<MyInput label="Company Email" class="mt-3"
											 placeholder="Company Email"
											 :value="form.header.companyEmail"
											 id="header-company-email"
											 key="header-company-email"
											 v-model="form.header.companyEmail"
									></MyInput>

									<MyInput label="Company Phone" class="mt-3"
											 placeholder="Company Phone"
											 :value="form.header.companyPhone"
											 id="header-company-phone"
											 key="header-company-phone"
											 v-model="form.header.companyPhone"
									></MyInput>
								</div>
							</Accordion>

							<Accordion title="Popup" ident="popup" v-if="isPopup">
								<div class="sf-item">
									<div class="form-group">
										<label for="popup-scroll">When to Show ({{form.popup.popupScroll}}%
											Scroll)</label>
										<input type="range" class="custom-range" min="5" max="85" id="popup-scroll"
											   v-model="form.popup.popupScroll">
									</div>
									<RadioGroup
											id="popup-popupLimin"
											key="popup-popupLimit"
											v-model="form.popup.popupLimit"
											:options="yesNoOptions"
											label="Set a Number of Times Popup Appears"
											:inline="true"
									/>


									<MyInput v-if="form.popup.popupLimit"
											 label="Popup Count"
											 type="number"
											 :value="form.popup.popupCount"
											 id="form-popup-popupCount"
											 key="form-popup-popupCount"
											 v-model="form.popup.popupCount"
									></MyInput>

									<RadioGroup
											id="popup-url-check"
											key="popup-url-check"
											v-model="form.popup.urlCheck"
											:options="yesNoOptions"
											v-on:change="setSelectedType"
											label="Set URL Check for the Popup"
											:inline="true"
									/>

									<Select v-if="form.popup.urlCheck"
											id="popup-url-check-type"
											label="URL Check Type"
											:options="checkOptions"
											v-model="form.popup.urlCheckType"
											v-on:input="changeSelectedType"
											:selectedOption="form.popup.urlCheckType">
									</Select>

									<MyInput v-if="form.popup.urlCheck"
											 label="URL"
											 placeholder="Type URL for URL Check Type"
											 :value="checkValue"
											 id="form-popup-urlCheck"
											 v-on:input="changeUrlCheckValue"
											 :key="`form-popup-urlCheck-${form.popup.urlCheckType}`"
											 v-model="checkValue"
									></MyInput>

									<ColorPicker label="Background Color"
												 id="form-popup-background-color"
												 key="form-popup-background-color"
												 :color="form.popup.bgColor"
												 v-model="form.popup.bgColor"/>

									<Select id="popup-where-to-show"
											label="Where To Show"
											:options="placementOptions"
											v-model="form.popup.selectPlacement"
											:selectedOption="form.popup.selectPlacement">
									</Select>
								</div>
							</Accordion>

							<Accordion title="Form Fields" ident="form-fields">
								<div class="sf-item">

									<MyInput label="Title"
											 :value="form.title"
											 id="form-fields-title"
											 key="form-fields-title"
											 v-model="form.title"
									></MyInput>

									<ColorPicker label="Title Color"
												 id="form-fields-title-color"
												 key="form-fields-title-color"
												 :color="form.titleColor"
												 v-model="form.titleColor"/>

									<div class="form-group">
										<label for="form-fields-description">Description</label>
										<textarea class="form-control"
												  id="form-fields-description"
												  v-model="form.description"
												  rows="1"
												  placeholder="Description goes here."
										></textarea>
									</div>

									<MyInput label="URL"
											 :value="form.url"
											 id="form-fields-url"
											 key="form-fields-url"
											 v-model="form.url"
									></MyInput>

									<MyInput label="Email"
											 :value="form.emailField.email"
											 id="form-fields-email"
											 key="form-fields-email"
											 v-model="form.emailField.email"
									></MyInput>

<!--									<template v-if="!form.emailField.view">-->
<!--										<div class="form-add" @click="showEmailField(true)"><span class="form-link">+ Add Email</span>-->
<!--										</div>-->
<!--									</template>-->
<!--									<div v-else>-->
<!--										<div class="form-group">-->
<!--											<label for="bfEmail">Email</label>-->
<!--											<div class="form-list">-->
<!--												<div class="form-list-item">-->
<!--													<div class="form-list-input">-->
<!--														<input class="form-control" id="bfEmail" type="email"-->
<!--															   v-model="form.emailField.email">-->
<!--														<div v-if="isEmbed" class="form-delete" @click="showEmailField(false)">-->
<!--															<span></span>-->
<!--														</div>-->
<!--													</div>-->
<!--													<div class="form-check custom-control custom-checkbox"-->
<!--														 v-if="isEmbed">-->
<!--														<input class="form-check-input custom-control-input"-->
<!--															   id="form-fields-email-required" type="checkbox"-->
<!--															   v-model="form.emailField.required">-->
<!--														<label class="form-check-label custom-control-label"-->
<!--															   for="form-fields-email-required"><span>Required</span></label>-->
<!--													</div>-->
<!--												</div>-->
<!--											</div>-->
<!--										</div>-->
<!--									</div>-->
									<template v-if="hasOtherFields">
										<div v-if="hasName">
											<div class="form-group">
												<label for="form-fields-otherFields-name">Name</label>
												<div class="form-list">
													<div class="form-list-item">
														<div class="form-list-input">
															<input class="form-control"
																   id="form-fields-otherFields-name" type="text"
																   v-model="form.otherFields.Name.value">
															<div class="form-delete" @click="deleteField('Name')">
																<span></span></div>
														</div>
														<div class="form-check custom-control custom-checkbox"
															 v-if="!isLanding">
															<input class="form-check-input custom-control-input"
																   id="form-fields-name-required" type="checkbox"
																   v-model="form.otherFields.Name.required">
															<label class="form-check-label custom-control-label"
																   for="form-fields-name-required"><span>Required</span></label>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div v-if="hasPhone">
											<div class="form-group">
												<label for="form-fields-otherFields-phone">Phone</label>
												<div class="form-list">
													<div class="form-list-item">
														<div class="form-list-input">
															<input class="form-control"
																   id="form-fields-otherFields-phone" type="text"
																   v-model="form.otherFields.Phone.value">
															<div class="form-delete" @click="deleteField('Phone')">
																<span></span></div>
														</div>
														<div class="form-check custom-control custom-checkbox"
															 v-if="!isLanding">
															<input class="form-check-input custom-control-input"
																   id="form-fields-phone-required" type="checkbox"
																   v-model="form.otherFields.Phone.required">
															<label class="form-check-label custom-control-label"
																   for="form-fields-phone-required"><span>Required</span></label>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div v-if="hasCompany">
											<div class="form-group">
												<label for="form-fields-otherFields-company">Company</label>
												<div class="form-list">
													<div class="form-list-item">
														<div class="form-list-input">
															<input class="form-control"
																   id="form-fields-otherFields-company" type="text"
																   v-model="form.otherFields.Company.value">
															<div class="form-delete" @click="deleteField('Company')">
																<span></span></div>
														</div>
														<div class="form-check custom-control custom-checkbox"
															 v-if="!isLanding">
															<input class="form-check-input custom-control-input"
																   id="form-fields-company-required" type="checkbox"
																   v-model="form.otherFields.Company.required">
															<label class="form-check-label custom-control-label"
																   for="form-fields-company-required"><span>Required</span></label>
														</div>
													</div>
												</div>
											</div>
										</div>

									</template>
									<template v-if="!hasOtherFields || canAddField">
										<div class="form-add" @click="showField"><span
												class="form-link">+ Add field</span></div>
									</template>
								</div>


							</Accordion>

							<Accordion title="Buttons" ident="form-buttons">
								<div class="sf-item">
									<MyInput label="Button Text"
											 :value="form.buttonText"
											 id="form-button-text"
											 key="form-button-text"
											 v-model="form.buttonText"
									></MyInput>

									<ColorPicker label="Button Text Color"
												 id="form-button-text-color"
												 key="form-button-text-color"
												 :color="form.buttonTextColor"
												 v-model="form.buttonTextColor"/>


									<ColorPicker label="Button Color"
												 id="form-button-color"
												 key="form-button-color"
												 :color="form.buttonColor"
												 v-model="form.buttonColor"/>
								</div>
							</Accordion>

							<Accordion title="Misc" ident="form-misc">
								<div class="sf-item">
									<RadioGroup
											id="form-isGdpr"
											key="form-isGdpr"
											v-model="form.isGdpr"
											:options="yesNoOptions"
											label="GDPR Compliance"
											:inline="true"
									/>

									<template v-if="form.isGdpr">
										<div class="form-group">
											<label for="form-GdprMessage">Default text</label>
											<textarea class="form-control" id="form-GdprMessage"
													  v-model="form.GdprMessage"
													  rows="4">
											</textarea>
										</div>
									</template>
								</div>

							</Accordion>

						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-8 col-md-12">

				<FormPreview :key="chosenFormat.type + 'form-preview'"
							 :type="chosenFormat.type"
							 :form-settings="form"/>
			</div>
		</div>

		<Modal modal-id="not-saved-changes" classNames="modal-dialog--big"
			   :hideTitle="true"
			   :hideButtons="true">
			<div>
				<p class="center">Before moving to the other page</p>
				<h2 class="center">Do you want to save your edits?</h2>
				<div class="modal-btns">
					<button class="btn btn-primary" type="button" @click="saveAndRedirect">Save</button>
					<button class="btn btn-secondary" type="button" @click="redirect">Do Not Save</button>
				</div>
			</div>
		</Modal>

	</div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Accordion from "../../../../../shared/components/Accordion";
    import Select from "../../../../../shared/components/Select";
    import RadioGroup from "../../../../../shared/components/RadioGroup";
    import MyInput from "../../../../../shared/components/Input";
    import Modal from "../../../../../shared/components/Modal";
    import ColorPicker from "../../../../../shared/components/ColorPicker";
    import {FONT_NAMES, FORMAT_TYPES} from "../../../../../shared/constants";
    import _ from 'lodash';
    import formHelper from "../../../../../shared/services/setupFormHelper"

    import UploadImage from "../../../../../shared/components/UploadImage";


    export default {
        name: "BuildForm",
        components: {
            RadioGroup,
            UploadImage,
            ColorPicker,
            FormPreview: () => import(/* webpackChunkName: "form-preview" */ "./components/FormPreview"),
            Modal,
            Select,
            Accordion,
            MyInput
        },
        data() {
            return {
                dataLoaded: false,
                checkValue: '',
                formatType: '',
                pageTitle: '',
                url: null,
                initialData: {},
                fieldArrayDefault: {
                    Name: {value: 'NAME', label: 'NAME', required: true},
                    Phone: {value: 'PHONE', label: 'PHONE', required: true},
                    Company: {value: 'COMPANY', label: 'COMPANY', required: true}
                },
                fieldArrayDefaultLanding: {
                    Email: {
                        label: 'EMAIL',
                        email: 'EMAIL',
                        value: 'EMAIL',
                        sequence: 'EM',
                        view: true,
                        required: true,
                        color: '#757575'
                    },
                    Url: {
                        label: 'URL',
                        value: 'URL',
                        sequence: 'U',
                        color: '#757575'
                    },
                    Name: {
                        label: 'NAME',
                        value: 'NAME',
                        sequence: 'NAM',
                        view: true,
                        required: false,
                        color: '#757575'
                    },
                    Phone: {
                        label: 'PHONE',
                        value: 'PHONE',
                        sequence: 'PHON',
                        view: true,
                        required: false,
                        color: '#757575'
                    },
                    Company: {
                        label: 'COMPANY',
                        value: 'COMPANY',
                        sequence: 'COMPA',
                        view: true,
                        required: false,
                        color: '#757575'
                    }


                },
                layoutOptions: [
                    {value: 'VERTICAL', text: 'Vertical'},
                    {value: 'HORIZONTAL', text: 'Horizontal'},
                ],
                fontOptions: [
                    {value: FONT_NAMES.ROBOTO, text: FONT_NAMES.ROBOTO},
                    {value: FONT_NAMES.OPEN_SANS, text: FONT_NAMES.OPEN_SANS},
                    {value: FONT_NAMES.LATO, text: FONT_NAMES.LATO},
                ],
                yesNoOptions: [
                    {
                        value: 1,
                        text: 'Yes',
                    },
                    {
                        value: 0,
                        text: 'No',
                    },
                ],

                checkOptions: [
                    {value: 'urlcontains', text: 'URL Contains'},
                    {value: 'urlstartswith', text: 'URL Starts With'},
                    {value: 'urlequals', text: 'URL Equals'}
                ],

                placementOptions: [
                    {value: 'Top', text: 'Top'},
                    {value: 'Center', text: 'Center'},
                    {value: 'Bottom', text: 'Bottom'}
                ],
                headerDefault: {
                    companyName: 'Your company',
                    companyEmail: 'company@email.com',
                    companyPhone: '+1 987-654-3210',
                    logo: 'https://siteauditor.s3.amazonaws.com/v2_logos/main/sa_logo.svg'
                },
                popupDefault: {
                    popupCount: 0,
                    popupLimit: 0,
                    selectPopupSize: "Medium",
                    popupScroll: 50,
                    bgColor: "#FFFFFF",
                    selectPlacement: "Top",
                    website: "https://growthrobotics.com",
                    urlCheckType: "",
                    urlcontent: "",
                    urlbeginswith: "",
                    urlmatches: "",
                    logoImage: false,
                    logoPath: "https://siteauditor.s3.amazonaws.com/assets/images/ImagePopup.jpg",
                    logoName: "logo.png",
                    //new property
                    urlCheck: 0

                },
                form: {
                    popup: {},
                    alignment: null,
                    font: FONT_NAMES.OPEN_SANS,
                    margin: false,
                    title: '',
                    description: '',
                    titleColor: '',
                    backgroundColor: '',
                    url: 'URL',
                    header: {
                        companyName: '',
                        companyEmail: '',
                        companyPhone: '',
                        logo: null
                    },
                    emailField: {
                        email: 'Email',
                        required: true,
                        view: true
                    },
                    otherFields: {
                        Name: null,
                        Phone: null,
                        Company: null
                    },

                    formFields: {},
                    buttonColor: '',
                    buttonTextColor: '',
                    buttonText: '',

                    isGdpr: false,
                    GdprMessage: ''
                },
                checkDirty: true
            }

        },
        created() {

            if (!this.chosenFormat.type) {
                this.$router.push({name: 'dashboard.setup-form.choose-format'})
            } else {
                this.formatType = this.chosenFormat.type;
                this.pageTitle = this.chosenFormat.title;
            }

            this.$store.dispatch('setupForm/getSettings', this.chosenFormat.type).then((response) => {

                this.settings = response.settings;

                this.form = formHelper.reformatData(
                    this.form,
                    response,
                    this.formatType,
                    this.popupDefault,
                    this.fieldArrayDefaultLanding,
                    this.headerDefault);

                if (this.isPopup) {
                    this.changeSelectedType();
                }
                this.initialData = JSON.parse(JSON.stringify(this.form));

                this.dataLoaded = true;
            }).catch((error) => {
                this.dataLoaded = true;
                this.showErrorNotifications(error);
            });

        },

        methods: {
            setSelectedType() {
                if(this.form.popup.urlCheck && !this.form.popup.urlCheckType) {
                    this.form.popup.urlCheckType = 'urlcontains';
                    this.checkValue = this.form.popup.urlcontent;
				}

			},

            changeSelectedType() {

                if (this.form.popup.urlCheckType === 'urlcontains') {

                    this.checkValue = this.form.popup.urlcontent;

                } else if (this.form.popup.urlCheckType === 'urlstartswith') {

                    this.checkValue = this.form.popup.urlbeginswith && this.form.popup.urlbeginswith.length ? this.form.popup.urlbeginswith : 'http://';

                } else if (this.form.popup.urlCheckType === 'urlequals') {

                    this.checkValue = this.form.popup.urlmatches;
                }
            },
            changeUrlCheckValue() {

                if (this.form.popup.urlCheckType === 'urlcontains') {

                    this.form.popup.urlcontent = this.checkValue;

                } else if (this.form.popup.urlCheckType === 'urlstartswith') {

                    this.form.popup.urlbeginswith = this.checkValue;

                } else if (this.form.popup.urlCheckType === 'urlequals') {

                    this.form.popup.urlmatches = this.checkValue;
                }

            },

            showEmailField(value) {
                this.form.emailField.view = value;
            },

            showField() {
                let fieldArray = this.isLanding ? this.fieldArrayDefaultLanding : this.fieldArrayDefault;
                if (!this.hasName) {
                    this.form.otherFields.Name = fieldArray.Name;
                    return;
                } else if (!this.hasPhone) {
                    this.form.otherFields.Phone = fieldArray.Phone;
                    return;
                } else if (!this.hasCompany) {
                    this.form.otherFields.Company = fieldArray.Company;
                    return;
                }
            },
            deleteField(item) {
                this.form.otherFields[item] = null;
            },

            save(route = null) {

                let sendData = JSON.parse(JSON.stringify(this.settings));

                if (this.form.isGdpr) {
                    sendData.gdpr_message = this.form.GdprMessage;
                }

                sendData.is_gdpr = this.form.isGdpr;
                sendData.is_margin = this.form.margin;

                sendData.css.buttonTextColor = this.form.buttonTextColor;
                sendData.css.buttonColor = this.form.buttonColor;
                sendData.css.inputFontfamily = this.form.font;
                sendData.css.titleColor = this.form.titleColor;

                let otherFieldsName = 'fieldArray';

                if (this.isLanding) {
                    otherFieldsName = 'selectedValues';
                }

                sendData.css[otherFieldsName] = [];

                for (let item in this.form.otherFields) {
                    if (this.form.otherFields[item]) {
                        sendData.css[otherFieldsName].push(this.form.otherFields[item])
                    }
                }

                if (this.isLanding) {

                    delete sendData.is_margin;

                    sendData.description = this.form.description;
                    sendData.title = this.form.title;
                    sendData.css.backgroundColor = this.form.backgroundColor;

                    sendData.css.formalignment = this.form.alignment;

                    sendData.css.header = JSON.parse(JSON.stringify(this.form.header));
                    sendData.css.header.logo = !sendData.css.header.logo ? sendData.css.header.logo : sendData.css.header.logo.split("?")[0];

                    sendData.button_text = this.form.buttonText;

                    this.form.emailField.value = this.form.emailField.email;

                    this.form.emailField.view = true;
                    this.form.emailField.required = true;

                    sendData.css.selectedValues.push(this.form.emailField);

                    let urlExists = false;

                    this.settings.css[otherFieldsName].forEach((element) => {
                        if (element.label === 'URL') {
                            element.value = this.form.url;
                            sendData.css.selectedValues.push(element);
                            urlExists = true;
                        }
                    });

                    if (!urlExists) {
                        this.fieldArrayDefaultLanding['Url'].value = this.form.url;
                        sendData.css.selectedValues.push(this.fieldArrayDefaultLanding['Url']);
                    }


                } else {
                    sendData.title.description = this.form.description;
                    sendData.title.buttonText = this.form.buttonText;
                    sendData.title.url = this.form.url;
                    sendData.title.formalignment = this.form.alignment;

                    sendData.title.title = this.form.title;
                    sendData.title.url = this.form.url;

                    if (this.isPopup) {
                        sendData.title.email = this.form.emailField.email;

                        if (!this.form.popup.urlCheck) {
                            this.form.popup.urlCheckType = "";
                        }

                        this.form.popup.popupLimit = !!this.form.popup.popupLimit;

                        if (!this.form.popup.popupLimit) {
                            this.form.popup.popupCount = 0;
                        }
                        this.form.popup.popupCount = Number(this.form.popup.popupCount);

                        sendData.popup = JSON.stringify(this.form.popup);

                    } else if (this.isEmbed) {
                        sendData.css.emailField = this.form.emailField;
                    }
                    sendData.title = JSON.stringify(sendData.title);
                }

                sendData.css = JSON.stringify(sendData.css);


                this.$store.dispatch('setupForm/setSettings', {type: this.chosenFormat.type, sendData: sendData})
                    .then(() => {
                        this.checkDirty = false;
                        let nextRoute = route ?? {name: 'dashboard.setup-form.embed-code'};

                        this.$router.push(nextRoute).catch(() => {});


                    }).catch((error) => {
                    this.showErrorNotifications(error);
                });
            },
            saveAndRedirect() {
                this.save(this.next)
            },
            redirect() {
                this.checkDirty = false;
                this.$router.push(this.next);
            },

            isDirty() {
                return !(_.isEqual(this.initialData, this.form));
            },

        },
        computed: {
            isEmbed() {
                return this.formatType === FORMAT_TYPES.EMBED;
            },
            isPopup() {
                return this.formatType === FORMAT_TYPES.POPUP;
            },
            isLanding() {
                return this.formatType === FORMAT_TYPES.LANDING_PAGE;
            },
            hasName() {
                return !!this.form.otherFields.Name
            },
            hasPhone() {
                return !!this.form.otherFields.Phone
            },
            hasCompany() {
                return !!this.form.otherFields.Company
            },
            hasOtherFields() {
                return this.hasName || this.hasPhone || this.hasCompany
            },
            canAddField() {
                return !(this.hasName && this.hasPhone && this.hasCompany)
            },
            iconName() {
                return this.isPopup ? 'svg-image-popup-code' : 'icon-code-' + this.formatType;
            },

            ...
                mapGetters({
                    chosenFormat: "setupForm/getFormat"
                })
        },

        beforeRouteLeave(to, from, next) {

            if (this.isDirty() && this.checkDirty && this.dataLoaded) {
                this.next = to;
                this.$bvModal.show('not-saved-changes')
            } else {
                next();
            }
        },

    }
</script>
